import React, { useRef, useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'

import Collapse from '@mui/material/Collapse'

import SingleTitleLayout from 'layouts/SingleTitle'
import FormRenderer from 'components/common/FormRenderer'

import statesJson from 'constants/states.json'

import useIntakeForm from 'hooks/useIntakeForm'

function CustomerIntakeForm() {
	useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formikRef = useRef(null)
  const { submitForm } = useIntakeForm()
  const [showThankYou, setShowThankYou] = useState(false)
  const [denialTiming, setDenialTiming] = useState('Clinical')
  const [appealType, setAppealType] = useState('Pre-Service Standard')

  const states = Object.keys(statesJson).map((key) => ({
    value: key,
    display: statesJson[key],
  }))

  const callTimes = [
    {
      value: '8AM-10AM',
      display: 'Early Morning (8AM - 10AM)',
    },
    {
      value: '10AM-12PM',
      display: 'Late Morning (10AM - 12PM)',
    },
    {
      value: '12PM-3PM',
      display: 'Early Afternoon (12PM - 3PM)',
    },
    {
      value: '3PM-6PM',
      display: 'Late Afternoon (3PM - 6PM)',
    },
    {
      value: '6PM-8PM',
      display: 'Evening (6PM - 8PM)',
    },
  ]

  const timings = ['Clinical', 'Benefits & Coverage']
  const caseTypes = [
    'Pre-Service Standard',
    'Pre-Service Emergent',
    'Post-Service Denial',
  ]
  const serviceLevels = ['Consultant Report', 'Full Service']
  const subscriberRelations = ['Self', 'Spouse', 'Dependant', 'Other']

  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

  const defaultValues = {
    customer_email: '',
    customer_phone: '',
    customer_first_name: '',
    customer_last_name: '',
    customer_insurance_company: '',
    customer_address_line1: '',
    customer_address_line2: '',
    customer_address_city: '',
    customer_address_state: null,
    customer_address_zip: '',
    customer_timezone: 'America/New_York',
    customer_subscriber_relationship: 'Self', // keep this
    customer_subscriber_relation: 'Self', // keep this
    customer_best_call_time: '',
    client_case_timing: 'Clinical',
    client_case_denied_datetime: '',
    client_case_service_datetime: '',
    client_case_due_date: '',
    client_case_appeal_type: 'Pre-Service Standard',
    client_case_service_level: 'Full Service',
  }

  const renderingData = [
    {
      field: 'customer_first_name',
      display: 'First name',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_last_name',
      display: 'Last name',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_email',
      type: 'email',
      display: 'Email address',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_address_line1',
      display: 'Mailing address',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_address_line2',
      display: 'Mailing address (Apt, suite, etc.)',
    },
    {
      field: 'customer_address_city',
      display: 'City',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_address_state',
      display: 'Residing State',
      type: 'autocomplete',
      options: states,
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_address_zip',
      display: 'Postal Code',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_phone',
      type: 'phone',
      display: 'Phone number',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'authorize-msg-receive',
      type: 'radio',
      inputProps: {
        required: true,
      },
      display:
        'Do you authorize A4YH to send / receive text message from this number? This is required in order to use our services.',
      options: [
        {
          value: 'yes',
          display: 'Yes',
        },
      ],
    },
    {
      field: 'customer_best_call_time',
      display: 'Best time to call',
      fieldType: 'select',
      options: callTimes,
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_timezone',
      display: 'Timezone you are located in',
      fieldType: 'select',
      options: [
        {
          display: 'Atlantic (AST)',
          value: 'America/Puerto_Rico',
        },
        {
          display: 'Eastern (EST/EDT)',
          value: 'America/New_York',
        },
        {
          display: 'Central (CST/CDT)',
          value: 'America/Chicago',
        },
        {
          display: 'Mountain (MST/MDT)',
          value: 'America/Denver',
        },
        {
          display: 'Phoenix (MST)',
          value: 'America/Phoenix',
        },
        {
          display: 'Pacific',
          value: 'America/Los_Angeles',
        },
        {
          display: 'Alaska',
          value: 'America/Anchorage',
        },
        {
          display: 'Hawaii',
          value: 'America/Honolulu',
        },
      ],
    },
    {
      field: 'customer_insurance_company',
      display: 'Insurance company',
      inputProps: {
        required: true,
      },
    },
    {
      field: 'customer_subscriber_relationship',
      type: 'radio',
      display: 'Are you the plan subscriber?',
      options: [
        {
          value: 'Self',
          display: 'Yes',
        },
        {
          value: 'Other',
          display: 'No',
        },
      ],
      callback: (value, { handleChange }) => {
        if (value === 'Self') {
          handleChange({
            target: {
              name: 'customer_subscriber_relation',
              value: 'Self',
            },
          })
        } else {
          handleChange({
            target: {
              name: 'customer_subscriber_relation',
              value: null,
            },
          })
        }
      },
      inputProps: {
        required: true,
      },
      children: {
        show: (value) => value && value !== 'Self',
        Transition: Collapse,
        fields: [
          {
            field: 'customer_subscriber_relation',
            display: 'What is your relationship to the subscriber?',
            fieldType: 'select',
            inputProps: {
              required: true,
            },
            options: subscriberRelations
              .filter((rel) => rel !== 'Self')
              .map((relation) => ({
                value: relation,
                display: relation,
              })),
          },
        ],
      },
    },
    {
      field: 'client_case_timing',
      fieldType: 'select',
      display: 'Denial Timing',
      callback: (value, { handleChange }) => {
        setDenialTiming(value)
        if (value === 'Clinical') {
          if (appealType?.startsWith('Post-Service')) {
            handleChange({
              target: {
                name: 'client_case_appeal_type',
                value: 'Pre-Service Standard',
              },
            })
            setAppealType('Pre-Service Standard')
          }
        } else if (
          value === 'Benefits & Coverage' &&
          appealType?.startsWith('Pre-Service')
        ) {
          handleChange({
            target: {
              name: 'client_case_appeal_type',
              value: 'Post-Service Denial',
            },
          })
          setAppealType('Post-Service Denial')
        }
      },
      options: [
        {
          value: 'Clinical',
          display: 'Pre-Service (Clinical)',
        },
        {
          value: 'Benefits & Coverage',
          display: 'Post-Service (Claims)',
        },
      ],
      inputProps: {
        required: true,
      },
      // children: {
      //   show: (value) => value,
      //   Transition: Collapse,
      //   fields: [

      //   ],
      // },
    },
    {
      field: 'client_case_appeal_type',
      display: 'Select the type of appeal',
      type: 'radio',
      large: true,
      callback: (value, { handleChange }) => {
        setAppealType(value)
      },
      inputProps: {
        required: true,
        readOnly: denialTiming !== 'Clinical',
      },
      options:
        denialTiming !== 'Clinical'
          ? [
              {
                value: 'Post-Service Denial',
                display:
                  'Post-service denied claim. The procedure has been performed and either part or all of the claim has been denied by the health insurance company.  Cost: $300 for a 3 hour assessment, $150/hour for subsequent time spent. ',
              },
            ]
          : [
              {
                value: 'Pre-Service Standard',
                display:
                  'Pre-service Standard. This definition applies to a denial for a service or procedure that requires authorization by the insurance company before it is performed. This request usually applies to elective procedures. Cost: $300 for a 3 hour assessment, $150/hour for subsequent time spent.',
              },
              {
                value: 'Pre-Service Emergent',
                display:
                  'Pre-Service Urget or Emergent. Same as Pre-service Standard, but it involves a condition that a prudent layperson standard. Prudent layperson is someone who possesses an average knowledge of medicine who believes that the condition, sickness or injury will cause imminent harm and 	that failure to obtain immediate medical attention would put the person’s health in jeopardy. Cost: $525 for a 3 hour assessment, $225/hour for subsequent time spent. ',
              },
            ],
    },
    {
      field: 'client_case_service_datetime',
      display:
        denialTiming === 'Clinical'
          ? 'What is the scheduled date of the service?'
          : 'What was the scheduled date of the procedure?',
      type: 'date',
    },
    {
      field: 'client_case_due_date',
      display: 'When is this claim appeal due to the insurance company?',
      type: 'date',
    },
    {
      field: 'client_case_denied_datetime',
      display: 'What was the date of the claim denial?',
      type: 'date',
    },
    {
      field: 'client_case_service_level',
      display: 'Select desired service level',
      type: 'radio',
      large: true,
      inputProps: {
        required: true,
      },
      options: [
        {
          value: 'Consultant Report',
          display:
            'Obtain an A4YH Consultant Report summarizing your case, reason for the denied claim or pre-authorization denial, and an assessment based upon the documents submitted to A4YH. Sometimes assistance may be needed to help interpret the letter and/or relevant records associated with the claim or pre-authorization denial. A4YH consultants can review written communications, including medical records, associated with the denial and provide a written summary that includes an explanation of the insurance company findings and suggested ways to approach an appeal. This service is offered only for non-complex cases for a fee of $225. ',
        },
        {
          value: 'Full Service',
          display: (
            <>
              Engage A4YH to “step into my shoes” and submit the appeal to the
              health insurance company on your behalf. This option is full
              service: I will authorize A4YH to submit the appeal on my behalf.
              This option includes a complete review of all case records,
              including medical records if relevant. Upon completion of
              appropriate authorizations, A4YH will directly submit the appeal
              to the insurance company and follow the case throughout the
              internal appeal process to the conclusion. If this is the
              preferred approach, you must also select a type of appeal below.
            </>
          ),
        },
      ],
    },
    {
      field: 'files',
      type: 'fileupload',
      display: (
        <>
          <Typography component="legend" variant="strong">
            If available, please gather and upload the following:
          </Typography>
          <ul>
            <li>- A copy of the denial letter you received</li>
            <li>- Explanation of Benefits (EOB)</li>
            <li>- Relevant medical records</li>
            <li>- A complete copy of your benefits plan</li>
          </ul>
        </>
      ),
    },
    {
      field: 'client_case_customer_signature',
      type: 'terms',
      scrollable: true,
      title: 'Please read and sign the following:',
      display:
        'By signing you read and understand Appeal4YourHealth’s pricing policies and are agreeing to authorize Appeal4YourHealth to review preliminary documents that may be HIPAA protected in order to perform an initial assessment of your case.',
      content: (
        <>
          <h4>Pricing Policy</h4>
          <p>
            Charges for medical record requests. It is very likely that medical
            records in the possession of the provider and/or insurance company
            may be needed to complete the record review and/or submit the appeal
            to the insurance company. If Appeal4YourHealth submits the record
            request to the insurance company, or provider on your behalf, there
            will be an additional charge. This charge will include the amount
            charged by the provider or insurance company plus a $50
            administrative fee. The administrative fee includes the costs
            incurred by Appeal4YourHealth to obtain the records.
            Appeal4YourHealth requires an up-front charge of $
            {appealType === 'Pre-Service Emergent' ? '525' : '300'} that will
            cover the first 3 hours of assessing your appeal. If the assessment
            of your appeal requires more than 3 hours, additional charges will
            apply.
          </p>
          <hr />
          <h4>Terms of Service</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit illo
            nostrum laboriosam modi sunt eius voluptatum numquam tempora
            possimus vero optio quia aperiam, consectetur illum similique
            voluptas voluptatem voluptates quam! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Velit illo nostrum laboriosam modi
            sunt eius voluptatum numquam tempora possimus vero optio quia
            aperiam, consectetur illum similique voluptas voluptatem voluptates
            quam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            illo nostrum laboriosam modi sunt eius voluptatum numquam tempora
            possimus vero optio quia aperiam, consectetur illum similique
            voluptas voluptatem voluptates quam! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Velit illo nostrum laboriosam modi
            sunt eius voluptatum numquam tempora possimus vero optio quia
            aperiam, consectetur illum similique voluptas voluptatem voluptates
            quam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            illo nostrum laboriosam modi sunt eius voluptatum numquam tempora
            possimus vero optio quia aperiam, consectetur illum similique
            voluptas voluptatem voluptates quam! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Velit illo nostrum laboriosam modi
            sunt eius voluptatum numquam tempora possimus vero optio quia
            aperiam, consectetur illum similique voluptas voluptatem voluptates
            quam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            illo nostrum laboriosam modi sunt eius voluptatum numquam tempora
            possimus vero optio quia aperiam, consectetur illum similique
            voluptas voluptatem voluptates quam! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Velit illo nostrum laboriosam modi
            sunt eius voluptatum numquam tempora possimus vero optio quia
            aperiam, consectetur illum similique voluptas voluptatem voluptates
            quam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
            illo nostrum laboriosam modi sunt eius voluptatum numquam tempora
            possimus vero optio quia aperiam, consectetur illum similique
            voluptas voluptatem voluptates quam!
          </p>
        </>
      ),
      inputProps: {
        required: true,
      },
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef.current && formikRef?.current?.handleSubmit(),
    },
  ]

  const handleSubmit = async (values, { setStatus }) => {
    if (await submitForm(values, setStatus)) {
      setShowThankYou(true)
    }
  }

  const validationSchema = Yup.object().shape({
    customer_email: Yup.string()
      .email('Value must be a valid email address')
      .max(256, 'Value must be 256 characters or less')
      .required('Email is required'),
    customer_phone: Yup.string()
      .matches(phoneRegex, 'Invalid phone number')
      .max(16, 'Phone # must be 16 characters or less')
      .required('Phone # is required'),
    'authorize-msg-receive': Yup.string().required('Please choose an option'),
    customer_first_name: Yup.string()
      .max(256, 'Value must be 256 characters or less')
      .required('First name is required'),
    customer_last_name: Yup.string()
      .max(256, 'Value must be 256 characters or less')
      .required('Last name is required'),
    customer_insurance_company: Yup.string()
      .max(128, 'Value must be 128 characters or less')
      .required('Insurance company is required'),
    customer_address_line1: Yup.string()
      .max(256, 'Value must be 256 characters or less')
      .required('Address is required'),
    customer_address_line2: Yup.string().max(
      256,
      'Value must be 256 characters or less'
    ),
    customer_address_city: Yup.string()
      .max(128, 'Value must be 128 characters or less')
      .required('City is required'),
    customer_address_state: Yup.object()
      .shape({
        value:
          Yup.string().oneOf[
            (states.map((state) => state.value),
            'State must be a valid U.S. state or territory')
          ],
        display: Yup.string(),
      })
      .nullable()
      .required('Residing state is required'),
    customer_address_zip: Yup.string()
      .max(11, 'Value must be 11 characters or less')
      .required('Zip is required'),
    customer_timezone: Yup.string()
      .max(56, 'Value must be 56 characters or less')
      .required('Timezone is required'),
    customer_subscriber_relation: Yup.string()
      .oneOf(
        subscriberRelations,
        `Subscriber relation must be one of the following: ${subscriberRelations.join(
          ', '
        )}`
      )
      .required('Subscriber relation is required'),
    customer_best_call_time: Yup.string()
      .oneOf(
        callTimes.map((ct) => ct.value),
        `Best call time must be one of the following: ${callTimes
          .map((ct) => ct.display)
          .join(', ')}`
      )
      .max(10, 'Value must be 10 characters or less')
      .required('Best call time is required'),
    client_case_timing: Yup.string()
      .oneOf(
        timings,
        `Case timing must be one of the following values: ${timings.join(', ')}`
      )
      .required('Case timing is required'),
    client_case_service_datetime: Yup.date().required(
      'Client case service datetime is required'
    ),
    client_case_denied_datetime: Yup.date().required(
      'Client case denied datetime is required'
    ),
    client_case_due_date: Yup.date().required(
      'Client case due date is required'
    ),
    client_case_appeal_type: Yup.string()
      .oneOf(
        caseTypes,
        `Appeal type must be one of the following values: ${caseTypes.join(
          ', '
        )}`
      )
      .required('Client case appeal type is required'),
    client_case_customer_signature_checkbox: Yup.bool()
      .oneOf(
        [true],
        'Please read and sign authorization & accept the terms and conditions'
      )
      .required(
        'Please read and sign authorization  & accept the terms and conditions'
      ),
    client_case_customer_signature: Yup.string().required(
      'You must sign off on this agreement before continuing'
    ),
    client_case_service_level: Yup.string()
      .oneOf(
        serviceLevels,
        `Service level must be one of the following values: ${serviceLevels.join(
          ', '
        )}`
      )
      .required('Client case service level is required'),
  })

  return showThankYou ? (
    <SingleTitleLayout title="Thank You!">
      <h4>Thank you for choosing us!</h4>
      <p>
        You should receive an email on how to log in to your personal case
        portal shortly, where you can view all information pertaining to your
        case.
      </p>
    </SingleTitleLayout>
  ) : (
    <SingleTitleLayout title="Customer Intake Form">
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        defaultValues={defaultValues}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </SingleTitleLayout>
  )
}

export default CustomerIntakeForm
