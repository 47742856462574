import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from 'styled-components';

const StyledAppBar = styled(AppBar)`
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > .MuiButton-root {
    margin: 5px;
  }

  @media (min-width: 600px) {
    justify-content: flex-end;
  }
`;

function Header() {
  const location = useLocation();

  // Check if the current path is '/diy'
  const isCustIntakeFormPage = location.pathname === '/customer-intake';
  const isDIYFormPage = location.pathname === '/diy';

  return (
    <>
      <StyledAppBar color="background">
        <Toolbar>
          <Typography variant="h1">Appeal4YourHealth</Typography>
        </Toolbar>
        {!isCustIntakeFormPage && !isDIYFormPage && (  // Hide buttons if on DIYForm page
          <StyledButtonContainer>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/diy"
            >
              Do It Yourself
            </Button>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/customer-intake"
            >
              Do It For Me
            </Button>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/console/user-login"
            >
              Console Login
            </Button>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/portal/login"
            >
              Customer Login
            </Button>
          </StyledButtonContainer>
        )}
      </StyledAppBar>
      <Box sx={{ height: '64px' }} />
    </>
  );
}

export default Header;
