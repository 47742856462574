import React from 'react';
import TitleSection from 'components/home/TitleSection';
import Content from 'components/home/Content';
import styled from 'styled-components';

const MobileAwareWrapper = styled.div`
  padding: 20px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const TitleSectionWrapper = styled.div`
  margin-bottom: 400px; /* Adjust the value as needed */
`;

function Home() {
  return (
    <MobileAwareWrapper>
      <TitleSectionWrapper>
        <TitleSection />
      </TitleSectionWrapper>
      <Content />
    </MobileAwareWrapper>
  )
}

export default Home;
