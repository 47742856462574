import React, { useRef, useEffect, useState } from 'react'
import axios from 'utils/axios'

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import resolveErrorText from 'helpers/resolveErrorText'

export default function StripeCheckoutForm({ clientSecret }) {
  const stripe = useStripe()
  const elements = useElements()
  const formikRef = useRef(null)
  const [message, setMessage] = useState(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe || !clientSecret || !hasSubmitted) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe])

  const handleSubmit = async (values, { setErrors }) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.

      // Make sure to disable form submission until Stripe.js has loaded.

      return
    }

    setHasSubmitted(true)

    setIsLoading(true)
    try {
      if (!values.email) {
        throw new Error('Email is required')
      }
      if (!values.name) {
        throw new Error('Name is required')
      }
      // do a call to the backend first
      // this will throw errors if they occur,
      const { data } = await axios.post('/diyintake/recordPaymentIntent', {
        client_secret: clientSecret,
        name: values.name,
        email: values.email,
      })

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.REACT_APP_BASE_URL}/diythanks?name=${data.name}&email=${data.email}`,
          payment_method_data: {
            billing_details: {
              name: data.name,
              email: data.email,
            },
          },
        },
      })

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occured.')
      }
    } catch (err) {
      setMessage(resolveErrorText(err))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {message && <Alert severity="warning">{message}</Alert>}
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validateOnChange={false}
        p={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'name',
                display: 'Your Name',
                type: 'text',
                InputProps: {
                  disabled: isLoading || !stripe || !elements,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'email',
                display: 'Your Email',
                type: 'email',
                InputProps: {
                  disabled: isLoading || !stripe || !elements,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentElement
              options={{
                fields: {
                  billingDetails: {
                    name: 'never',
                    email: 'never',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isLoading || !stripe || !elements}
              onClick={() => {
                formikRef.current.submitForm()
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Formik>
    </>
  )
}
