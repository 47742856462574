import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useSample from 'hooks/context/useSample';

const ContentWrapper = styled(Box)`
  margin-top: 10vh; /* Add margin to push content below TitleSection */
  & > .MuiBox-root:nth-child(odd) {
    background-color: white;
  }
`;

const TextSection = styled(Box)`
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 45vh;

  @media (max-width: 600px) {
    min-height: auto;
    padding: 10px;
  }

  & > .MuiContainer-root {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 8%;
  height: auto;
  margin-bottom: 0px;
  margin-top: 25px;

  @media (max-width: 600px) {
    width: 80%;
    max-width: none;
  }
`;

function ContentSection({ children, image, html }) {
  return (
    <TextSection>
      {image && <Image src={image} alt="siteimages" />}
      <Container maxWidth="md">
        {html ? (
          <Typography fontSize="large" dangerouslySetInnerHTML={{ __html: html }} />
        ) : (
          <Typography fontSize="large">{children}</Typography>
        )}
      </Container>
    </TextSection>
  );
}

function Content() {
  const { about } = useSample();

  const textContents = [
    'Navigating health insurance benefits can be difficult and overwhelming. The Affordable Care Act – also known as Obamacare – requires more transparency but denial letters can still be convoluted and confusing when explaining your appeal options.',
    'Appeal4YourHealth consists of lawyers, doctors and nurses who all have experience working for health insurance companies. They are familiar with processes and procedures used by insurance companies to deny claims or disapprove a requested service. Be advised A4YH does not offer clinical services or medical advice. <br/> <br/> Consult your physician regarding any specific medical questions/issues.',
    'For an initial fee we can help you decide how to proceed and offer different options – from guidelines to “do it yourself” to stepping into your shoes and pursuing the appeal on your behalf. If you decide to engage our services, we will give you information in writing that describes the different options and fees.',
    'We follow confidentiality standards and federal HIPAA requirements which are in place to protect your personal information – including medical records, denial letters, claims and any other documents that contain your personal health information.',
    (
      <>
        If you are interested in seeking our services click on this link to  
        {' '}
        <Link to="/customer-intake">complete the intake form</Link>
        . The form asks for preliminary information and includes a waiver that says you are authorizing us to review records and/or documents relevant to your appeal. We will keep the records and any communications with you confidential. It also says that you agree to pay a nonrefundable $300 triage fee that will cover a preliminary assessment of your case. This will include a review of the documents you send and an intake telephone conversation with an Appeal4YourHealth expert to discuss case details. The average total triage time is three (3) hours.
      </>
    ),
    'At the conclusion of the triage process you can choose one of the options described above – they will be discussed during the triage call and at the same time you will be presented with a written description of each option and associated fees. If you decide you do not want our help, you are under no obligation to use our services.',
    'We strive to be as transparent as possible in every way and will do our best to make sure that you understand what goes on at every step. You will have direct online access to view your account and have access to all records, including checking the status and communicate directly with us. Any preliminary questions can be sent to us at <a href="mailto:info@appeal4yourhealth.com">info@appeal4yourhealth.com</a>'
  ];

  return (
    <ContentWrapper>
      {textContents.map((text, index) => (
        <ContentSection 
          key={index} 
          image={
            index === 0 ? '/static/imgs/home/caution.png' : 
            index === 1 ? '/static/imgs/home/doc_lawyer.png' : 
            index === 2 ? '/static/imgs/home/save_money.png' : 
            index === 3 ? '/static/imgs/home/secure.png' : 
            index === 4 ? '/static/imgs/home/form.png' : 
            index === 5 ? '/static/imgs/home/triage.png' : 
            index === 6 ? '/static/imgs/home/updates.png' : 
            null
          } 
          html={typeof text === 'string' ? text : null}
        >
          {typeof text !== 'string' && text}
        </ContentSection>
      ))}
      <ContentSection>
        <Button LinkComponent={Link} to="/console/user-login">User Login Page</Button>
        <Button LinkComponent={Link} to="/console">Console Page</Button>
      </ContentSection>
    </ContentWrapper>
  );
}

export default Content;
