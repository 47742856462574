import React from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Header from './Header';
import Footer from 'components/layout/Footer';
import styled from 'styled-components';

const MainContainer = styled(Box)`
  min-height: 90vh;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

function PublicFacing({ children }) {
  return (
    <>
      <CssBaseline />
      <Header />
      <MainContainer>
        <Outlet />
      </MainContainer>
      <Footer />
    </>
  );
}

export default PublicFacing;
