import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  text-align: center;
`;

const StyledContainer = styled(Container)`
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  @media (max-width: 600px) {
    margin-top: ${({ mobileMarginTop }) => mobileMarginTop}px;
    margin-bottom: ${({ mobileMarginBottom }) => mobileMarginBottom}px;
  }
`;

function SingleTitle({ title, children }) {
  return (
    <>
      <StyledTypography variant="h1">
        {title}
      </StyledTypography>
      <StyledContainer maxWidth="sm" marginTop={64} marginBottom={64} mobileMarginTop={32} mobileMarginBottom={32}>
        {children}
      </StyledContainer>
    </>
  );
}

export default SingleTitle;
